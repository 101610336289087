<template>

  <div class="group pl-4 border-l-8 border-transparent hover:border-l-sky-400 hover:cursor-pointer h-14 w-full flex items-center justify-between transition-all duration-250 ease-in-out">
    <p class="text-sky-500 text-lg" @click="citizenDossier" >{{ citizen.citizen }}</p>
    <div class="hidden group-hover:flex gap-1">
      <button @click="showReason" class="hidden border border-sky-500 rounded h-8 w-8 mr-2 text-2xl flex justify-center items-center p-0 text-sky-500 group-hover:bg-sky-500 group-hover:bg-opacity-10 transition-all duration-150 ease-in-out">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
        </svg>
      </button>
      <button @click="removeCitizen" class="border border-sky-500 rounded h-8 w-8 mr-4 text-2xl flex justify-center items-center p-0 text-sky-500 group-hover:bg-sky-500 group-hover:bg-opacity-10 transition-all duration-150 ease-in-out">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
          <path d="M11 6a3 3 0 11-6 0 3 3 0 016 0zM14 17a6 6 0 00-12 0h12zM13 8a1 1 0 100 2h4a1 1 0 100-2h-4z" />
        </svg>
      </button>
    </div>
  </div>
  <div class="hidden h-0 overflow-hidden group pl-4 border-l-8 border-transparent hover:border-l-sky-400 hover:cursor-pointer w-full items-center justify-between transition-all duration-250 ease-in-out">
    <p class="text-sky-500 text-lg">{{ citizen.reason }}</p>
  </div>


</template>

<script>
export default {
  name: 'CitizenList',
  props: ['citizenprop', 'citizenindex'],
  data() {
    return {
      citizen: this.citizenprop,
    }
  },
  methods: {
    removeCitizen() {
      this.$emit('removecitizen-index', this.citizenindex);
    },
    showReason() {
      console.log(this.citizen.reason);
    },
    citizenDossier() {
      let rsiUri = 'https://robertsspaceindustries.com/citizens/';
      let citizenUri = rsiUri + this.citizen.citizen;
      window.open(citizenUri);
    }
  }
};
</script>

